export const hideLoadingDiv = () => {
  const classes = ['load-anim-div', 'tsp-search-loading-animation'];

  const elements = classes.flatMap((className) =>
    Array.from(document.getElementsByClassName(className)),
  );

  elements.forEach((element) => {
    (element as HTMLElement).style.display = 'none';
  });
};
