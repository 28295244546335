import './loadingCards.scss';

import React, { PropsWithChildren } from 'react';

export type LoadingProps = PropsWithChildren<{
  text?: string;
  numCards?: number;
}>;

export const Loading: React.FC<LoadingProps> = ({ text, numCards = 4 }) => (
  <div className="ts-container">
    <div className="loading-cards">
      {[...Array(numCards)].map((_, i) => (
        <div key={i} className="loading-card-container">
          <div className="card-loading"></div>
        </div>
      ))}
    </div>
    {text != null ? (
      <div className="loading-text w-100">
        <h4 className="text-center m-t-20">{text}</h4>
      </div>
    ) : null}
  </div>
);
