import { Selector, SelectorOptionsType } from '@thrivesports/design-system';
import history from 'history/hash'; // eslint-disable-line node/file-extension-in-import
import React, { useCallback, useContext, useMemo } from 'react';

import { AppAttrsCtx } from '../context/AppAttrsCtx.js';
import { defaultSearchRadius } from '../hooks/useDefaultSearchAPI.js';
import { useSearchParams } from '../hooks/useSearchParams.js';

const mapEnabled = false;

export type RadiusSelectorProps = {
  className: string;
  itemOptionClassName: string;
  isPreview?: boolean;
};

export const RadiusSelector: React.FC<RadiusSelectorProps> = ({
  className,
  itemOptionClassName,
  isPreview,
}) => {
  const appAttributes = useContext(AppAttrsCtx);

  const { radius: searchRadius } = useSearchParams();

  let defaultRadiusSelection: string | number = defaultSearchRadius;
  if (searchRadius != null) {
    if (searchRadius === 'dynamic') {
      defaultRadiusSelection = 'dynamic';
    } else if (searchRadius != null) {
      defaultRadiusSelection = parseInt(searchRadius, 10);
    }
  }

  const handleSelection = useCallback(
    (newVal: string) => {
      const queryParams = new URLSearchParams(history.location.search);
      queryParams.set('radius', newVal);

      const newUrl = new URL(
        `${appAttributes.navbarTargetPath}#/?${queryParams.toString()}`,
        window.location.origin,
      );
      newUrl.hash = `${
        isPreview ? '/' : '/search/map'
      }?${queryParams.toString()}`;
      window.location.assign(newUrl.toString());
    },
    [appAttributes.navbarTargetPath, isPreview],
  );

  const options = useMemo(() => {
    const list: SelectorOptionsType[] = [
      {
        valueOf: () => 10,
        toString: () => '10 miles',
        comp: <p className={itemOptionClassName}>10 miles</p>,
      },
      {
        valueOf: () => 25,
        toString: () => '25 miles',
        comp: <p className={itemOptionClassName}>25 miles</p>,
      },
      {
        valueOf: () => 50,
        toString: () => '50 miles',
        comp: <p className={itemOptionClassName}>50 miles</p>,
      },
      {
        valueOf: () => 100,
        toString: () => '100 miles',
        comp: <p className={itemOptionClassName}>100 miles</p>,
      },
      {
        valueOf: () => 300,
        toString: () => '300 miles',
        comp: <p className={itemOptionClassName}>300 miles</p>,
      },
    ];
    if (mapEnabled && !isPreview) {
      list.push({
        valueOf: () => 'dynamic',
        toString: () => 'Map Radius',
        comp: <p className={itemOptionClassName}>Map Radius</p>,
      });
    }

    return list;
  }, [isPreview, itemOptionClassName]);

  return (
    <Selector
      icon={({ isOpen }) => (
        <span
          className={`tsi-chevron-${isOpen ? 'u' : 'd'} ts-selector-icon`}
        ></span>
      )}
      onSelect={handleSelection}
      defaultSelected={defaultRadiusSelection}
      options={options}
      className={className}
    />
  );
};
