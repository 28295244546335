/* eslint-disable no-underscore-dangle */
import { Loading } from '@thrivesports/design-system';
import {
  DbElasticSearchCoach,
  fromDbElasticSearchCoach,
  PublicCoach,
} from '@thrivesports/shared';
import history from 'history/hash'; // eslint-disable-line node/file-extension-in-import
import React, { useContext, useEffect, useMemo, useState } from 'react';

import { CoachCard } from '../components/CoachCard.js';
import EventRow from '../components/EventRow.js';
import { RadiusSelector } from '../components/RadiusSelector.js';
import { AppAttrsCtx } from '../context/AppAttrsCtx.js';
import {
  allSports,
  defaultResultSetSize,
  useDefaultSearchAPI,
} from '../hooks/useDefaultSearchAPI.js';
import { useDefaultSearchRequest } from '../hooks/useDefaultSearchRequest.js';
import { useGeocodedLocation } from '../hooks/useGeocodedLocation.js';
import { useIsAtUserLocation } from '../hooks/useIsAtUserLocation.js';
import { useSearchAPI } from '../hooks/useSearchAPI.js';
import { useSearchParams } from '../hooks/useSearchParams.js';
import getLocationsFromResults from '../utils/getLocationsFromResults.js';
import { updateHashSearchParam } from '../utils/navigation.js';
import type {
  MapPoint,
  SearchAPIRawResponse,
  SearchResultsCoachHitInterface,
  SearchResultsEventHitInterface,
} from '../utils/types.js';

type SelectedLocation = {
  point: MapPoint;
  hits: SearchResultsCoachHitInterface[] | SearchResultsEventHitInterface[];
};

export const SearchResultsView = () => {
  const appAttributes = useContext(AppAttrsCtx);

  useDefaultSearchRequest();

  const { results: defaultData, status: defaultSearchStatus } =
    useDefaultSearchAPI();
  const { results: searchData, status: searchStatus } = useSearchAPI();

  const data = searchData || defaultData;

  const geocodedLocation = useGeocodedLocation();

  const { resultType: selected, sport: sportCode } = useSearchParams();

  const [isMobile, setIsMobile] = useState<false | 'list' | 'map'>(false);

  useEffect(() => {
    const resizeListener = () => {
      if (window.innerWidth <= 3000 && isMobile === false) {
        // This is based off the $tssm-md-rule: 720px;
        setIsMobile('map');
      } else if (window.innerWidth > 3000 && isMobile !== false) {
        setIsMobile(false);
      }
    };
    window.addEventListener('resize', resizeListener);
    resizeListener(); // Run once to set isMobile default value
    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, [isMobile]);

  // scroll to top on first render
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  const coaches = useMemo(
    () =>
      data?.results.coaches.hits.map((hit) =>
        // eslint-disable-next-line no-underscore-dangle
        fromDbElasticSearchCoach(hit._source as DbElasticSearchCoach),
      ),
    [data?.results.coaches.hits],
  );

  let resultsListClass = '';
  if (isMobile === 'list') {
    resultsListClass = 'active';
  } else if (isMobile !== false) {
    resultsListClass = 'disabled';
  }

  let sportLabel = null;
  if (sportCode != null) {
    sportLabel =
      appAttributes.sportDropdownWhitelist.find((s) => s.code === sportCode)
        ?.name || 'All Sports';
  }

  return (
    <div className={`tssm-map-results ${isMobile !== false ? 'mobile' : ''}`}>
      {searchStatus === 'loading' || defaultSearchStatus === 'loading' ? (
        <Loading text="Searching..." />
      ) : (
        <div className={`tssm-map-results-list ${resultsListClass}`}>
          <div className="tssm-map-results-list-heading">
            <div className="tssm-map-results-list-heading-left">
              <div className="tssm-map-results-result-title">
                <div className="tssm-map-results-result-title-sport">
                  <span>{sportLabel}</span>
                </div>
                <div className="tssm-map-results-result-title-location">
                  <span>
                    {geocodedLocation != null
                      ? `${geocodedLocation.city}, ${geocodedLocation.state}, ${geocodedLocation.country}`
                      : null}
                  </span>
                </div>
              </div>
            </div>
            <div className="tssm-map-results-list-heading-right">
              <ResultSummary {...{ resultType: selected, data }} />
              <div
                className={`tssm-map-results-mobile-mode ${
                  isMobile !== false ? 'active' : ''
                }`}
              ></div>

              <div className="tssm-map-results-type">
                <button
                  onClick={() => {
                    updateHashSearchParam('results', 'coaches');
                  }}
                  className={`ts-btn tssm-map-results-type-btn tssm-map-results-type-btn-coaches ${
                    selected === 'coaches' ? 'active' : ''
                  }`}
                >
                  Coaches
                </button>
                <button
                  onClick={() => {
                    updateHashSearchParam('results', 'events');
                  }}
                  className={`ts-btn tssm-map-results-type-btn tssm-map-results-type-btn-events ${
                    selected === 'events' ? 'active' : ''
                  }`}
                >
                  Events
                </button>
              </div>
            </div>
          </div>

          <div className="tssm-map-results-list-items">
            <div className="tssm-coaches-ctn tssm-events-ctn tssm-map-results-grid">
              {selected === 'coaches' ? (
                <CoachResults coaches={coaches} />
              ) : (
                <EventResults {...{ data }} />
              )}
            </div>
          </div>

          <div className="tssm-map-results-bottom">
            <button
              type="button"
              className="btn tssm-map-results-scroll-to-top-btn"
              onClick={() => {
                window.scroll({
                  top: 0,
                  left: 0,
                  behavior: 'smooth',
                });
              }}
            >
              Scroll to Top
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

type ResultSummaryProps = {
  resultType: string;
  data?: SearchAPIRawResponse;
};

const ResultSummary: React.FC<ResultSummaryProps> = ({ resultType, data }) => {
  const { radius } = useSearchParams();

  const selectedLocation = useMemo(() => {
    if (data && radius === 'point') {
      const { point } = (history.location.state as SelectedLocation) || {};
      if (point) {
        const locations = getLocationsFromResults(data);
        const loc = locations.coachLocations.find(
          (l) => l.lat === point.lat && l.lng === point.lng,
        );
        if (loc) {
          return loc.location;
        }
      }
    }
    return null;
  }, [data, radius]);

  if (data == null) {
    return null;
  }

  let resultsCount;
  if (resultType === 'events') {
    resultsCount = data.results.events.hits.length;
  } else if (resultType === 'coaches') {
    resultsCount = data.results.coaches.hits.length;
  }

  let resultsLabel =
    resultsCount === defaultResultSetSize
      ? `${defaultResultSetSize}`
      : `${resultsCount}`;

  if (selectedLocation) {
    const { hits } = (history.location.state as SelectedLocation) || {};
    if (hits) {
      resultsLabel = `${hits.length}`;
    }
  }
  let resultTypeLabel = resultType === 'events' ? 'events' : 'coaches';
  if (resultsCount === 1) {
    resultTypeLabel = resultType === 'events' ? 'event' : 'coach';
  }

  return (
    <div className="tssm-map-results-result-num">
      <span className="tssm-map-results-result-num-text-ctn">
        <span className="tssm-map-results-result-num-highlight">
          {' '}
          {resultsLabel}{' '}
        </span>
        <span className="tssm-map-results-result-num-text">
          {resultTypeLabel}
        </span>
      </span>

      {selectedLocation ? (
        <>
          <span className="tssm-map-results-result-num-text">at </span>
          <span className="tssm-map-results-sel-loc-title">
            {selectedLocation.name}, {selectedLocation.address_1}{' '}
            {selectedLocation.city}, {selectedLocation.state_province},{' '}
            {selectedLocation.country}
          </span>
        </>
      ) : (
        <>
          <RadiusSelector
            className="ts-btn tssm-map-results-radius-sel "
            itemOptionClassName="tssm-map-results-radius-sel-items"
          />
        </>
      )}
    </div>
  );
};

const EventResults: React.FC<{
  data?: SearchAPIRawResponse;
}> = ({ data }) => {
  const results = data?.results.events.hits;

  return (
    <>
      {data && results?.length === 0 ? <NoResults searchFor="events" /> : null}
      {results?.slice(0, 100).map((event, eventIndex) => (
        <EventRow key={eventIndex} event={event} className="tssm-event-row" />
      ))}
      {results && results.length > 100 ? (
        <div className="tssm-map-results-see-more">
          Only showing the first 100 events of {results.length}. Refine your
          search to see more results.
        </div>
      ) : null}
    </>
  );
};

const maxCoaches = 52;

const CoachResults: React.FC<{ coaches?: PublicCoach[] }> = ({ coaches }) => (
  <>
    {coaches?.length === 0 ? <NoResults searchFor="coaches" /> : null}
    {coaches?.slice(0, maxCoaches).map((coach, coachIndex) => (
      <CoachCard key={coachIndex} coach={coach} className="tssm-coach-row" />
    ))}
    {coaches && coaches.length > maxCoaches ? (
      <div className="tssm-map-results-see-more">
        Only showing the first {maxCoaches} coaches of {coaches.length}. Refine
        your search to see more results.
      </div>
    ) : null}
  </>
);

const NoResults: React.FC<{ searchFor: string }> = ({ searchFor }) => {
  const appAttributes = useContext(AppAttrsCtx);
  const isAtUserLocation = useIsAtUserLocation();
  const { sport: urlSport } = useSearchParams();

  const sportLabel = useMemo(() => {
    if (urlSport && urlSport !== allSports) {
      const sport = appAttributes.sportDropdownWhitelist.find(
        (s) => s.code === urlSport,
      );
      if (sport) {
        return sport.lowercased();
      }
    }
    return '';
  }, [appAttributes.sportDropdownWhitelist, urlSport]);

  return (
    <div className="tssm-map-results-list-no-res">
      <h4 className="tssm-map-results-list-no-res-title">No exact matches</h4>
      <p className="tssm-map-results-list-no-res-txt">
        Uh-oh! We couldn't find any {sportLabel} {searchFor}{' '}
        {isAtUserLocation ? 'near you' : 'here'}.
      </p>
      <p className="tssm-map-results-list-no-res-sub">
        Try adjusting your filters to explore more coaches and events.
      </p>
    </div>
  );
};
