export type UserType = 'coach' | 'student';

export class User {
  id!: string;
  firstName!: string;
  lastName!: string;
  emailAddresses!: UserEmail[];

  userType?: UserType;
}

export class UserEmail {
  dbId!: string;
  email!: string;
  primary!: boolean;
}

export class UserName {
  displayName: string;
  firstName?: string;
  lastName?: string;

  constructor(displayName: string, firstName?: string, lastName?: string) {
    this.displayName = displayName;
    this.firstName = firstName;
    this.lastName = lastName;

    if (firstName == null && lastName == null) {
      const names = displayName.split(' ');
      if (names.length > 1) {
        const [first] = names;
        this.firstName = first;
        this.lastName = names.slice(1).join(' ');
      } else {
        this.firstName = displayName;
      }
    }
  }
}
