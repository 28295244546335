export class ApiResponse<TResult> {
  success: boolean;
  statusCode: number;
  result?: TResult;
  errorMessage?: string;
  errorCode?: string;
  errorCodes?: string[];

  constructor(success: boolean, statusCode: number) {
    this.success = success;
    this.statusCode = statusCode;
  }

  get hasError(): boolean {
    return (
      this.errorMessage != null ||
      this.errorCode != null ||
      (this.errorCodes != null && this.errorCodes.length > 0)
    );
  }

  isSuccess(): this is ApiResponse<TResult> & { result: TResult } {
    return this.success;
  }
}
