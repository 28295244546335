/* eslint-disable no-underscore-dangle */
import type { SearchAPIRawResponse } from './types.js';

export interface LocationInterface {
  id: number;
  lat: number;
  lng: number;
  count: number;
  location: {
    address_1: string;
    address_2: string;
    city: string;
    country: string;
    name: string;
    state_province: string;
  };
}

export interface LocationsInterface {
  coachLocations: LocationInterface[];
  eventLocations: LocationInterface[];
}

const getLocationsFromResults = (
  resultsToGetLocationsFrom: SearchAPIRawResponse,
): LocationsInterface => {
  const newLocations: LocationsInterface = {
    coachLocations: [],
    eventLocations: [],
  };
  resultsToGetLocationsFrom.results.coaches.hits.forEach((coach) => {
    coach._source.locations.forEach((location) => {
      newLocations.coachLocations.push({
        id: location.id,
        lat: Number(location.point.lat),
        lng: Number(location.point.lon),
        count: 1,
        location: {
          ...location,
        },
      });
    });
  });
  resultsToGetLocationsFrom.results.events.hits.forEach((event) => {
    const location = event._source.locations;
    newLocations.eventLocations.push({
      id: location.id,
      lat: Number(location.point.lat),
      lng: Number(location.point.lon),
      count: 1,
      location: {
        ...location,
      },
    });
  });
  return newLocations;
};
export default getLocationsFromResults;
