import React from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';

import SearchBarView from './SearchBarView.js';

const SearchBarRouterView = () => (
  <Router>
    <Routes>
      <Route path="/*" element={<SearchBarView />} />
    </Routes>
  </Router>
);
export default SearchBarRouterView;
