import { backgroundPlaceholder } from '@thrivesports/shared';
import React, { useContext } from 'react';

import type { ScriptAttrSuggestedLocationInterface } from '../attributes/scriptAttrRequirements.js';
import { AppAttrsCtx } from '../context/AppAttrsCtx.js';
import { defaultSearchRadius } from '../hooks/useDefaultSearchAPI.js';
import { defaultZoom } from '../hooks/useDefaultSearchRequest.js';

export type LocationCardProps = {
  location: ScriptAttrSuggestedLocationInterface;
};

const LocationCard: React.FC<LocationCardProps> = ({ location }) => {
  const appAttributes = useContext(AppAttrsCtx);

  const locationPath = `${appAttributes.navbarTargetPath}#/search/map?results=coaches&radius=${defaultSearchRadius}&latitude=${location.latitude}&longitude=${location.longitude}&zoom=${defaultZoom}&term=${location.place}`;

  return (
    <div className="tssm-location-card">
      <div className="tssm-location-card-inner">
        <a
          href={new URL(locationPath, window.location.origin).toString()}
          className="tssm-location-card-inner-content"
        >
          <div
            className="tssm-location-card-bg"
            style={{
              backgroundImage: `url('${
                location.backgroundSrc || backgroundPlaceholder
              }')`,
            }}
          />
          <p className="tssm-location-card-title">{location.name}</p>
        </a>
      </div>
    </div>
  );
};
export default LocationCard;
