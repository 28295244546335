import { initWindowForThriv, SyncManager } from '@thrivesports/shared';
import history from 'history/hash'; // eslint-disable-line node/file-extension-in-import
import { useContext, useEffect } from 'react';

import { UserLocationCtx } from '../context/UserLocationCtx.js';
import { defaultSearchRadius } from './useDefaultSearchAPI.js';

export const syncKeyDefaultSearch = 'DEFAULT_SEARCH';

export const defaultZoom = 12;

/**
 * This hook will update the search parameters with default values.
 * Default latitude and longitude are taken from the UserLocationContext.
 *
 * This will only set default URL parameters a single time for a given page load.
 *
 * It is safe to use this on multiple components as it uses a syncManager
 * to prevent simultaneous URL mutations.
 */
export const useDefaultSearchRequest = () => {
  // if an SyncManager already exists on the page, use it. Otherwise create
  // one which can be used by subsequent consumers
  if (!window.Thriv) {
    initWindowForThriv();
  }
  if (!window.Thriv.Sync) {
    window.Thriv.Sync = {};
  }
  if (!window.Thriv.Sync[syncKeyDefaultSearch]) {
    window.Thriv.Sync[syncKeyDefaultSearch] = new SyncManager<void>();
  }

  const syncManager = window.Thriv.Sync[syncKeyDefaultSearch];

  const { isDefaultLocation, setLatLng } = useContext(UserLocationCtx);

  useEffect(() => {
    // need to check and see if an effect is already in progress
    if (syncManager.getSyncStatus() === 'idle') {
      // must kick off the request in the same event loop
      syncManager.setSyncStatus('loading');

      navigator.geolocation.getCurrentPosition((geoLoc) => {
        const currentQueryParams = new URLSearchParams(history.location.search);
        const newQueryParams = new URLSearchParams(currentQueryParams);

        const radius = currentQueryParams.get('radius');
        if (
          radius == null ||
          (Number.isNaN(radius as unknown as number) && radius !== 'dynamic')
        ) {
          newQueryParams.set('radius', `${defaultSearchRadius}`);
        }

        const newLatLong = { lat: 0, lng: 0 };

        const latitude = currentQueryParams.get('latitude');
        if (
          (latitude == null || Number.isNaN(latitude as unknown as number)) &&
          geoLoc.coords.latitude
        ) {
          newQueryParams.set('latitude', geoLoc.coords.latitude.toString());
          newLatLong.lat = geoLoc.coords.latitude;
        }

        const longitude = currentQueryParams.get('longitude');
        if (
          (longitude == null || Number.isNaN(longitude as unknown as number)) &&
          geoLoc.coords.longitude
        ) {
          newQueryParams.set('longitude', geoLoc.coords.longitude.toString());
          newLatLong.lng = geoLoc.coords.longitude;
        }

        const zoom = currentQueryParams.get('zoom');
        if (zoom == null || Number.isNaN(zoom as unknown as number)) {
          newQueryParams.set('zoom', `${defaultZoom}`);
        }

        if (Array.from(newQueryParams).length > 0) {
          // Apply updates to query params
          history.push({ search: `?${newQueryParams.toString()}` });

          if (newLatLong.lat !== 0 && newLatLong.lng !== 0) {
            setLatLng(newLatLong);
          } else if (
            geoLoc.coords.latitude &&
            geoLoc.coords.longitude &&
            isDefaultLocation
          ) {
            setLatLng({
              lat: geoLoc.coords.latitude,
              lng: geoLoc.coords.longitude,
            });
          }

          syncManager.setSyncStatus('done');
        }
      });
    }
  }, [isDefaultLocation, setLatLng, syncManager]);
};
