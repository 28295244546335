export class Sport {
  sportId!: string;
  code!: string;
  name!: string;

  lowercased(): string {
    return this.name
      .split(' ')
      .map((namePart) => namePart.toLowerCase())
      .join(' ');
  }
}
