import { GoogleMap, Marker } from '@react-google-maps/api';
import { WaitForGoogleMaps } from '@thrivesports/design-system';
import history from 'history/hash'; // eslint-disable-line node/file-extension-in-import
import React, { useContext, useMemo } from 'react';

import { RadiusSelector } from '../components/RadiusSelector.js';
import { AppAttrsCtx } from '../context/AppAttrsCtx.js';
import { UserLocationCtx } from '../context/UserLocationCtx.js';
import {
  allSports,
  defaultResultSetSize,
} from '../hooks/useDefaultSearchAPI.js';
import { useDefaultSearchRequest } from '../hooks/useDefaultSearchRequest.js';
import { useGeocodedLocation } from '../hooks/useGeocodedLocation.js';
import { useSearchAPI } from '../hooks/useSearchAPI.js';
import { useSearchParams } from '../hooks/useSearchParams.js';
import getLocationsFromResults from '../utils/getLocationsFromResults.js';

const MapPreviewView = () => {
  const appAttributes = useContext(AppAttrsCtx);
  const userLocation = useContext(UserLocationCtx);

  useDefaultSearchRequest();
  const { results } = useSearchAPI();

  const { latitude, longitude, sport } = useSearchParams();

  const geocodedLocation = useGeocodedLocation();

  const locations = useMemo(() => {
    if (results) {
      return getLocationsFromResults(results);
    }
    return null;
  }, [results]);

  const resultsCount = results?.results.coaches.hits.length;
  let resultsLabel = '?';
  if (resultsCount === defaultResultSetSize) {
    resultsLabel = `${defaultResultSetSize}`;
  } else if (resultsCount) {
    resultsLabel = `${resultsCount}`;
  }

  const mapLinkHREF = useMemo(() => {
    const queryParams = new URLSearchParams(history.location.search);
    queryParams.set('results', 'coaches');
    const url = new URL(
      `${appAttributes.navbarTargetPath}#/search/map?${queryParams}`,
      window.location.origin,
    ).toString();
    return url;
  }, [appAttributes.navbarTargetPath]);

  const searchedForSportLabel = useMemo(() => {
    if (!sport || sport === allSports) {
      return '';
    }
    const sportObj = appAttributes.sportDropdownWhitelist.find(
      (s) => s.code === sport,
    );
    if (sportObj) {
      return sportObj.lowercased();
    }
    return '';
  }, [appAttributes.sportDropdownWhitelist, sport]);

  return (
    <div className="tssm-search-hero">
      <div className="tssm-search-hero-inner">
        <div className="tssm-landing-header-content-bg">
          <div className="tssm-landing-header-content-bg-image hide-me"></div>
        </div>
        <div className="tssm-landing-header-content ts-container">
          <p className="tssm-landing-header-location">
            {geocodedLocation != null
              ? `${geocodedLocation.city}, ${geocodedLocation.state}`
              : null}
          </p>
          {resultsCount != null && (
            <div className="tssm-landing-header-subtitle">
              {resultsCount != null && resultsCount > 0 ? (
                <div className="tssm-landing-header-location-results">
                  <span className="tssm-map-preview-result-num-text">
                    We have found
                  </span>
                  <span className="tssm-map-preview-result-num-highlight">
                    {resultsLabel}
                  </span>
                  <span className="tssm-map-preview-result-num-text">
                    {searchedForSportLabel}{' '}
                    {resultsCount === 1 ? 'coach ' : 'coaches '} in
                  </span>
                  <RadiusSelector
                    className="tssm-map-preview-radius-sel"
                    itemOptionClassName="tssm-map-preview-radius-sel-items"
                    isPreview
                  />
                </div>
              ) : null}
              {resultsCount === 0 ? (
                <div className="tssm-landing-header-location-results">
                  No {searchedForSportLabel} coaches found in
                </div>
              ) : null}
            </div>
          )}
        </div>
      </div>

      <div className="tssm-map-preview-container">
        <WaitForGoogleMaps>
          <a href={mapLinkHREF} className="tssm-map-preview">
            <GoogleMap
              mapContainerStyle={{
                minHeight: ' 300px',
                width: '100%',
                cursor: 'pointer',
              }}
              center={{
                lat: Number(latitude) || userLocation.latLng.lat,
                lng: Number(longitude) || userLocation.latLng.lng,
              }}
              zoom={10}
              options={{
                disableDefaultUI: true,
                maxZoom: 20,
                disableDoubleClickZoom: true,
                draggable: false,
                scrollwheel: false,
                panControl: false,
              }}
            >
              {locations?.coachLocations.map((location, locationIndex) => (
                <Marker
                  key={locationIndex}
                  position={{
                    lat: parseFloat(location.lat.toString()),
                    lng: parseFloat(location.lng.toString()),
                  }}
                  icon={
                    location.count > 1
                      ? appAttributes.mapMultiIcon
                      : appAttributes.mapSingleIcon
                  }
                  label={
                    location.count > 1
                      ? { text: location.count.toString(), color: 'white' }
                      : undefined
                  }
                />
              ))}
              {locations?.eventLocations.map((location, locationIndex) => (
                <Marker
                  key={locationIndex}
                  position={{
                    lat: parseFloat(location.lat.toString()),
                    lng: parseFloat(location.lng.toString()),
                  }}
                  icon={
                    location.count > 1
                      ? appAttributes.mapMultiIcon
                      : appAttributes.mapSingleIcon
                  }
                  label={
                    location.count > 1
                      ? { text: location.count.toString(), color: 'white' }
                      : undefined
                  }
                />
              ))}
            </GoogleMap>
          </a>
        </WaitForGoogleMaps>
      </div>
    </div>
  );
};
export default MapPreviewView;
