import {
  ApiResponse,
  RequestData,
  requestDataAsFetchOptions,
  UseSingletonAPIRequestFetcher,
} from '@thrivesports/shared';

import type { SearchAPIRawResponse } from './types.js';

export type SearchAPIParams = {
  url: string;
  partner: string;
};

export class SearchAPIResponse extends ApiResponse<SearchAPIRawResponse> {}

// use the fetch API
export async function searchAPIRequest(
  url: string,
  data: RequestData,
): Promise<SearchAPIResponse> {
  const fetchOptions = requestDataAsFetchOptions(data);
  fetchOptions.credentials = 'omit';
  const fetchResponse = await fetch(url, fetchOptions);

  const response = new SearchAPIResponse(
    fetchResponse.ok,
    fetchResponse.status,
  );
  response.result = (await fetchResponse.json()) as SearchAPIRawResponse;
  return response;
}

export const buildFetcher = ({
  url,
  partner,
}: SearchAPIParams): UseSingletonAPIRequestFetcher<SearchAPIRawResponse> => {
  const requestParams = new RequestData('', 'GET', undefined, {
    headers: {
      'x-thriv-partner': partner, // needed to enable services with thriv API
    },
  });
  return () => searchAPIRequest(url, requestParams);
};
