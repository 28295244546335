import { Photo } from '../../media.js';
import type { DbAPIMedia, DbPhoto } from '../mediafile.js';

export type DbCoachPhoto = DbPhoto & {
  golfpro_photo_id: string;
};
export type DbCoachCoverPhoto = DbPhoto & {
  golfpro_coverphoto_id: string;
};
export type DbCoachBusinessLogo = {
  coach_businesslogo_id: string;
  image_base_url: string;
  image_large: string;
};
export type DbElasticSearchCoachPhoto = {
  l: string;
  t?: string;
};

export const fromDbPhoto = (dbPhoto?: DbPhoto) => {
  const photo = new Photo();
  if (
    dbPhoto?.profile_photo_base_url != null &&
    dbPhoto?.profile_photo_large != null
  ) {
    photo.url = `${dbPhoto.profile_photo_base_url}${dbPhoto.profile_photo_large}`;
  }
  if (dbPhoto?.profile_photo_thumbnail != null) {
    photo.thumbnailUrl = dbPhoto.profile_photo_thumbnail;
  }

  return photo;
};

export const fromAPIPhoto = (apiPhoto: DbAPIMedia) => {
  const photo = new Photo();
  photo.url = apiPhoto.l;

  if (apiPhoto.t != null) {
    photo.thumbnailUrl = apiPhoto.t;
  }

  return photo;
};

export const fromDbElasticSearchCoachPhoto = (
  dbPhoto: DbElasticSearchCoachPhoto,
) => {
  const photo = new Photo();
  photo.url = dbPhoto.l;

  if (dbPhoto.t != null) {
    photo.thumbnailUrl = dbPhoto.t;
  }

  return photo;
};
