import { Loader } from '@googlemaps/js-api-loader';
import type { Location } from 'history';
import history from 'history/hash'; // eslint-disable-line node/file-extension-in-import
import { useCallback, useContext, useEffect, useState } from 'react';

import { AppAttrsCtx } from '../context/AppAttrsCtx.js';
import { UserLocationCtx } from '../context/UserLocationCtx.js';

export const useGeocodedLocation = () => {
  const appAttributes = useContext(AppAttrsCtx);
  const { latLng } = useContext(UserLocationCtx);

  const [googleMapsReady, setGoogleMapsReady] = useState(false);

  const [geocodedLocation, setGeocodedLocation] =
    useState<google.maps.GeocoderResponse | null>(null);

  const listener = useCallback(
    ({ location }: { location: Location }) => {
      if (!googleMapsReady) {
        return;
      }

      const queryParams = new URLSearchParams(location.search);
      const lat = queryParams.get('latitude') || latLng.lat;
      const lng = queryParams.get('longitude') || latLng.lng;

      const geocoder = new google.maps.Geocoder();
      geocoder
        .geocode({ location: { lat: Number(lat), lng: Number(lng) } })
        .then((geocodeResults) => {
          setGeocodedLocation(geocodeResults);
        });
    },
    [googleMapsReady, latLng.lat, latLng.lng],
  );

  useEffect(() => {
    if (window.google?.maps != null) {
      setGoogleMapsReady(true);
    } else {
      const loader = new Loader({
        apiKey: appAttributes.googleMapsKey,
        version: 'weekly',
      });
      loader.importLibrary('geocoding').then(() => {
        setGoogleMapsReady(true);
      });
    }
  }, [appAttributes.googleMapsKey]);

  // TODO make sure this only fires on an actual lat or long change
  useEffect(() => {
    const unlisten = history.listen(listener);
    return unlisten;
  });

  useEffect(() => {
    listener({ location: history.location });
  }, [listener]);

  const result = geocodedLocation?.results?.[0];
  if (result != null) {
    let city = result.address_components.find((addr) =>
      addr.types.includes('locality'),
    )?.long_name;
    if (city == null) {
      city = result.address_components.find((addr) =>
        addr.types.includes('sublocality'),
      )?.long_name;
    }
    const state = result.address_components.find((addr) =>
      addr.types.includes('administrative_area_level_1'),
    )?.short_name;
    const country = result.address_components.find((addr) =>
      addr.types.includes('country'),
    )?.long_name;

    return { city, state, country };
  }
  return null;
};
