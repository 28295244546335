import React, { useContext, useEffect } from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';

import { AppAttrsCtx } from '../context/AppAttrsCtx.js';
import { SearchResultsView } from './SearchResultsView.js';

const ContentView = () => {
  const AppAttributes = useContext(AppAttrsCtx);

  useEffect(() => {
    /* 
      If the user for some reason is redirected to search the old way, convert their url to the new one
    */
    const urlParams = new URLSearchParams(window.location.search);

    const searchParams = urlParams.get('search');
    const queryParams = new URLSearchParams(window.location.search);

    if (searchParams) {
      const newUrl = new URL(
        `${AppAttributes.navbarTargetPath}#/?${queryParams.toString()}`,
        window.location.origin,
      );
      newUrl.hash = `/search/results/${searchParams}`;
      newUrl.search = '';
      window.location.assign(newUrl.toString());
    } else if (!window.location.hash.length && queryParams.size > 0) {
      // The URL contains query params but no hash, so move the params into the hash
      const newUrl = new URL(
        AppAttributes.navbarTargetPath,
        window.location.origin,
      );
      newUrl.hash = `#/?${queryParams.toString()}`;
      newUrl.search = '';
      window.location.assign(newUrl.toString());
    }
  }, [AppAttributes.navbarTargetPath]);

  return (
    <div className="tssm-content">
      <Router>
        <Routes>
          <Route path="*" element={<SearchResultsView />} />
        </Routes>
      </Router>
    </div>
  );
};
export default ContentView;
