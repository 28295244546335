import React, { PropsWithChildren } from 'react';

type TruncatedTextProps = PropsWithChildren<{
  text?: string;
  truncateLimit?: number;
}>;

export const TruncatedText: React.FC<TruncatedTextProps> = ({
  text,
  truncateLimit = 180,
  children,
}) => {
  let display = <>{text}</>;
  if (text != null && text.length >= truncateLimit) {
    display = (
      <>
        {text.substring(0, truncateLimit)}...
        {children}
      </>
    );
  }

  return display;
};
