import {
  constructPhpUrl,
  DbMarketplaceSport,
  fromDbMarketplaceSport,
  ThrivePhpApiClient,
  useSingletonAPIRequest,
} from '@thrivesports/shared';
import { useCallback, useMemo } from 'react';

import { ScriptAttrRequirementsInterface } from '../attributes/scriptAttrRequirements.js';

export const useAPISports = (
  appAttributes: ScriptAttrRequirementsInterface,
) => {
  const baseUrl = constructPhpUrl(appAttributes.targetApi);

  const fetchSports = useCallback(
    () =>
      new ThrivePhpApiClient(baseUrl).get<DbMarketplaceSport[]>(
        'api/public/getSports',
      ),
    [baseUrl],
  );

  const { results: dbSports, status } = useSingletonAPIRequest(
    'sports',
    fetchSports,
  );

  const sports = useMemo(() => {
    if (dbSports?.length) {
      return dbSports.map((dbSport: DbMarketplaceSport) =>
        fromDbMarketplaceSport(dbSport),
      );
    }
    return [];
  }, [dbSports]);

  return {
    sports,
    status,
  };
};
