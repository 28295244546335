import type { ScriptAttrRequirementsInterface } from '../attributes/scriptAttrRequirements.js';

export interface ThemeStyleVarsInterface {
  primaryColor?: string;
  secondaryColor?: string;
  borderColor?: string;
  headersColor?: string;
  textPrimary?: string;
  textSecondary?: string;
  animOpenTime?: string;
  animCloseTime?: string;
}

/* 
  This sets the actual vars per element according to the theme
*/
const setVars = (ele: HTMLElement, theme: ThemeStyleVarsInterface) => {
  if (theme.primaryColor) {
    ele.style.setProperty('--style-color-primary', theme.primaryColor); // Set both the tssm and ts variants
    ele.style.setProperty('--ts-primary-color', theme.primaryColor);
  }
  if (theme.secondaryColor) {
    ele.style.setProperty('--tssm-secondary-color', theme.secondaryColor);
    ele.style.setProperty('--ts-secondary-color', theme.secondaryColor);
  }
  if (theme.borderColor) {
    ele.style.setProperty('--tssm-border-color', theme.borderColor);
    ele.style.setProperty('--ts-border-color', theme.borderColor);
  }
  if (theme.headersColor) {
    ele.style.setProperty('--tssm-headers-color', theme.headersColor);
    ele.style.setProperty('--ts-headers-color', theme.headersColor);
  }
  if (theme.textPrimary) {
    ele.style.setProperty('--tssm-text-primary', theme.textPrimary);
    ele.style.setProperty('--ts-text-primary', theme.textPrimary);
  }
  if (theme.textSecondary) {
    ele.style.setProperty('--tssm-text-secondary', theme.textSecondary);
    ele.style.setProperty('--ts-text-secondary', theme.textSecondary);
  }
  if (theme.animOpenTime) {
    ele.style.setProperty('--tssm-anim-open-time', theme.animOpenTime);
    ele.style.setProperty('--ts-anim-open-time', theme.animOpenTime);
  }
  if (theme.animCloseTime) {
    ele.style.setProperty('--tssm-anim-close-time', theme.animCloseTime);
    ele.style.setProperty('--ts-anim-close-time', theme.animCloseTime);
  }
};

/* 
  Set the color vars in the css for tssm with JS
*/
const setStyleVars = (appAttributes: ScriptAttrRequirementsInterface) => {
  const theme: ThemeStyleVarsInterface = {}; // Create the theme obj
  if (appAttributes.primaryColor) {
    theme.primaryColor = appAttributes.primaryColor;
  }
  if (appAttributes.secondaryColor) {
    theme.secondaryColor = appAttributes.secondaryColor;
  }
  if (appAttributes.borderColor) {
    theme.borderColor = appAttributes.borderColor;
  }
  if (appAttributes.headersColor) {
    theme.headersColor = appAttributes.headersColor;
  }
  if (appAttributes.textPrimary) {
    theme.textPrimary = appAttributes.textPrimary;
  }
  if (appAttributes.textSecondary) {
    theme.textSecondary = appAttributes.textSecondary;
  }
  if (appAttributes.animOpenTime) {
    theme.animOpenTime = appAttributes.animOpenTime;
  }
  if (appAttributes.animCloseTime) {
    theme.animCloseTime = appAttributes.animCloseTime;
  }

  /* 
    Set the style vars for all our different components
  */

  Array.from(
    document.getElementsByClassName(
      appAttributes.contentInjectionSiteClassName,
    ),
  ).forEach((ele) => {
    if ((ele as HTMLElement).style) {
      setVars(ele as HTMLElement, theme);
    }
  });

  Array.from(
    document.getElementsByClassName(appAttributes.navbarInjectionSiteClassName),
  ).forEach((ele) => {
    if ((ele as HTMLElement).style) {
      setVars(ele as HTMLElement, theme);
    }
  });

  Array.from(
    document.getElementsByClassName(
      appAttributes.eventsSectionInjectionSiteClassName,
    ),
  ).forEach((ele) => {
    if ((ele as HTMLElement).style) {
      setVars(ele as HTMLElement, theme);
    }
  });

  Array.from(
    document.getElementsByClassName(
      appAttributes.mapTileSectionInjectionSiteClassName,
    ),
  ).forEach((ele) => {
    if ((ele as HTMLElement).style) {
      setVars(ele as HTMLElement, theme);
    }
  });

  Array.from(
    document.getElementsByClassName(
      appAttributes.coachesSectionInjectionSiteClassName,
    ),
  ).forEach((ele) => {
    if ((ele as HTMLElement).style) {
      setVars(ele as HTMLElement, theme);
    }
  });

  Array.from(
    document.getElementsByClassName(
      appAttributes.suggestedLocationsSectionInjectionSiteClassName,
    ),
  ).forEach((ele) => {
    if ((ele as HTMLElement).style) {
      setVars(ele as HTMLElement, theme);
    }
  });
};
export default setStyleVars;
