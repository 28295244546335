export type AttrTypesType =
  | 'string'
  | 'number'
  | 'integer'
  | 'float'
  | 'boolean'
  | 'stringArray'
  | 'numArray'
  | 'integerArray'
  | 'floatArray'
  | 'jsonFetch';

export interface AttrisetAttrInterface {
  attribute: string;
  internalAttribute: string; // Must be a keyof ReturnedType
  type: AttrTypesType;
  description: string; // Used for documentation later
}

const currentScript = document.currentScript as HTMLScriptElement; // Probably won't ever error

/* 
  This takes an array obj describing the script attributes required, 
    retrieves them and overrides any default settings with them,
    makes any requests for JSON from a url,
    and returns a promise that resolves with the attributes and json in a single object from a promise.
  
  ReturnedType is the desired object type that is returned.
    This should reflect what you put in for the attributeDescriptor param
*/
export const Attriset = <ReturnedType>(
  attributeDescriptor: AttrisetAttrInterface[],
  defaultAttributes: ReturnedType,
) => {
  const prom = new Promise<ReturnedType>((resolveProm) => {
    const currentScriptAttrs = currentScript.attributes;

    const finalAttributes = defaultAttributes;

    let idCtr = 0;
    const getIdForProm = () => {
      // Just gets ids for promises for jsonFetch attrs. Used for tracking promises
      idCtr++;
      return idCtr;
    };

    // Hold all the promises for jsonFetch type and the main promise
    let promisesToWaitFor: { prom: Promise<void>; id: number }[] = [];

    // Check all the results of the promises to see if it's ok to resolve and return a result for attrs
    const checkReadyToResolve = (id: number) => {
      promisesToWaitFor = promisesToWaitFor.filter((p) => p.id !== id);
      if (promisesToWaitFor.length === 0) {
        resolveProm(finalAttributes);
      }
    };

    const mainId = getIdForProm();
    const mainProm = new Promise<void>((mainResolve) => {
      attributeDescriptor.forEach((attr) => {
        const item = currentScriptAttrs.getNamedItem(attr.attribute); // The raw attr
        /* 
          Find out what datatype the attr is, then conv it to that type and assign it to the attr
        */
        if (attr.type == null || attr.type === 'string') {
          if (item) {
            (finalAttributes[
              attr.internalAttribute as keyof ReturnedType
            ] as unknown as string) = item.value.toString();
          }
        } else if (attr.type === 'integer') {
          if (item && !Number.isNaN(item.value as unknown as number)) {
            const v: string = item.value;
            (finalAttributes[
              attr.internalAttribute as keyof ReturnedType
            ] as unknown as number) = parseInt(v, 10);
          }
        } else if (attr.type === 'float') {
          if (item && !Number.isNaN(item.value as unknown as number)) {
            const v: string = item.value;
            (finalAttributes[
              attr.internalAttribute as keyof ReturnedType
            ] as unknown as number) = parseFloat(v);
          }
        } else if (attr.type === 'boolean') {
          if (item) {
            (finalAttributes[
              attr.internalAttribute as keyof ReturnedType
            ] as unknown as boolean) = Boolean(item.value);
          }
        } else if (attr.type === 'stringArray') {
          if (item) {
            (finalAttributes[
              attr.internalAttribute as keyof ReturnedType
            ] as unknown as string[]) = item.value.toString().split(',');
          }
        } else if (attr.type === 'numArray') {
          if (item) {
            (finalAttributes[
              attr.internalAttribute as keyof ReturnedType
            ] as unknown as number[]) = item.value
              .toString()
              .split(',')
              .map((v) => Number(v));
          }
        } else if (attr.type === 'integerArray') {
          if (item) {
            (finalAttributes[
              attr.internalAttribute as keyof ReturnedType
            ] as unknown as number[]) = item.value
              .toString()
              .split(',')
              .map((v) => Number(v));
          }
        } else if (attr.type === 'floatArray') {
          if (item) {
            (finalAttributes[
              attr.internalAttribute as keyof ReturnedType
            ] as unknown as number[]) = item.value
              .toString()
              .split(',')
              .map((v) => Number(v));
          }
        } else if (attr.type === 'jsonFetch') {
          /* 
            Throw this into promisesToWaitFor and when all these are resolved we return a result for attrs
          */
          const fetchJsonId = getIdForProm();
          const fetchJsonProm = new Promise<void>((fetchJsonResolve) => {
            if (item) {
              fetch(item.value)
                .then((raw) => raw.text())
                .then((rawText) => {
                  finalAttributes[
                    attr.internalAttribute as keyof ReturnedType
                  ] = JSON.parse(rawText); // The amount of annoying typecasts I have to do...
                  checkReadyToResolve(fetchJsonId);
                  fetchJsonResolve();
                })
                .catch((err) => {
                  // eslint-disable-next-line no-console
                  console.error(
                    `Error retrieving ${attr.attribute} from url provided`,
                    err,
                  );
                  checkReadyToResolve(fetchJsonId);
                  fetchJsonResolve();
                });
            } else {
              // If the property is missing, just return
              checkReadyToResolve(fetchJsonId);
              fetchJsonResolve();
            }
          });
          promisesToWaitFor.push({ id: fetchJsonId, prom: fetchJsonProm });
        }
      });
      checkReadyToResolve(mainId);
      mainResolve();
    });
    promisesToWaitFor.push({ id: mainId, prom: mainProm });
  });
  return prom;
};
