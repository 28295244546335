import React from 'react';

export const Star = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19.113"
    height="18.178"
    viewBox="0 0 19.113 18.178"
  >
    <g
      id="Group_1109"
      data-name="Group 1109"
      transform="translate(0.537 0.565)"
    >
      <path
        id="Path_560"
        data-name="Path 560"
        d="M9.019,0l2.788,5.648,6.232.905-4.51,4.4,1.064,6.207L9.019,14.224,3.445,17.156,4.51,10.949,0,6.553l6.232-.905Z"
        fill="#3d3d6b"
        strokeWidth="0.5"
      />
    </g>
  </svg>
);
