// TODO can we modify this eslint rule directly so we aren't always disabling for this import?
import history from 'history/hash'; // eslint-disable-line node/file-extension-in-import

export const updateHashSearchParam = (key: string, value: string) => {
  const queryParams = new URLSearchParams(history.location.search);
  queryParams.set(key, value);
  history.push({
    search: `?${queryParams.toString()}`,
  });
};
