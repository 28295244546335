import { useContext } from 'react';

import { UserLocationCtx } from '../context/UserLocationCtx.js';
import { arePointsProximate } from '../utils/geometry/arePointsProximate.js';
import { useSearchParams } from './useSearchParams.js';

// is the lat/lng in the hash search parameters proximate to the user's current location?
export const useIsAtUserLocation = () => {
  const { latLng: userLatLng } = useContext(UserLocationCtx);
  const { latitude, longitude } = useSearchParams();

  if (!latitude || !longitude) {
    return false;
  }
  return arePointsProximate(
    { latitude: parseFloat(latitude), longitude: parseFloat(longitude) },
    { latitude: userLatLng.lat, longitude: userLatLng.lng },
  );
};
