/* eslint-disable @typescript-eslint/no-explicit-any */
export function removeEmpty(obj: {
  [key: string]: any;
}): Record<string, string | File> {
  Object.keys(obj).forEach((key) => {
    if (obj[key] == null) {
      delete obj[key];
    }
  });
  return obj;
}
