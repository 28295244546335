import type { FormBody, RequestOptions } from './types.js';

export class RequestData {
  path: string;
  method: string;
  body?: FormBody;
  options?: RequestOptions;

  constructor(
    path: string,
    method: string,
    body?: FormBody,
    options?: RequestOptions,
  ) {
    this.path = path;
    this.method = method;
    this.body = body;
    this.options = options;
  }

  public asFormData(): FormData {
    const formBody = new FormData();
    if (this.body != null) {
      const thisBody = this.body;
      Object.keys(this.body).forEach((key) => {
        if (Array.isArray(thisBody[key])) {
          (thisBody[key] as string[]).forEach((value) =>
            formBody.append(`${key}[]`, value),
          );
        }
        formBody.append(key, thisBody[key] as string);
      });
    }
    return formBody;
  }

  public asUrlSearchParams(): URLSearchParams {
    const searchParams = new URLSearchParams();
    if (this.body != null) {
      const thisBody = this.body;
      Object.keys(thisBody).forEach((key) => {
        if (Array.isArray(thisBody[key])) {
          (thisBody[key] as string[]).forEach((value) =>
            searchParams.append(`${key}[]`, value),
          );
        } else {
          searchParams.append(key, thisBody[key] as string);
        }
      });
    }
    return searchParams;
  }
}
