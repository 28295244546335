const defaultDecimalPlaces = 1;

export type GeoPoint = {
  latitude: number;
  longitude: number;
};

export const arePointsProximate = (
  point1: GeoPoint,
  point2: GeoPoint,
  decimalPlaces = defaultDecimalPlaces,
) => {
  const precision = 10 ** decimalPlaces;
  const { latitude: lat1, longitude: long1 } = point1;
  const { latitude: lat2, longitude: long2 } = point2;

  return (
    Math.round(lat1 * precision) === Math.round(lat2 * precision) &&
    Math.round(long1 * precision) === Math.round(long2 * precision)
  );
};
