export function determineEnv(): string {
  const { hostname } = window.location;
  if (hostname.includes('stage')) {
    return 'stage';
  }
  if (hostname.includes('localdev')) {
    return 'local';
  }
  return 'prod';
}

/**
 * Given an environment name and path, constructs a URL object for either v1 or v2 of the node.js API.
 *
 * @param targetAPI
 * @param path
 * @returns
 */
export const constructApiUrl = (targetAPI: string, path: string): URL => {
  let host = 'https://api.thrivsports.com';
  switch (targetAPI) {
    case 'stage':
      host = 'https://stage-api.thrivsports.com';
      break;
    case 'dev':
    case 'local':
      host = 'http://localhost';
      if (path?.startsWith('v1')) {
        host += ':3030';
      } else if (path?.startsWith('v2')) {
        host += ':3031';
      }
      break;
    default:
  }
  return new URL(`${host}/api/${path}`);
};

export const constructPhpUrl = (targetAPI: string) => {
  let host = 'https://thrivesports.us';
  switch (targetAPI) {
    case 'stage':
      host = 'https://stage.thrivesports.us';
      break;
    case 'local':
      host = 'https://localdev.thrivesports.us';
      break;
    default:
  }
  return host;
};
