import { isThriveCoach } from '../utils/urlHelpers.js';
import type { Availability } from './availability.js';
import type { CoachProfile } from './coach/profile.js';
import type { CoachRate } from './coach/rate.js';
import type { CoachReview } from './coach/review.js';
import type { College } from './college.js';
import type { Event } from './event.js';
import type { Location } from './location.js';
import type { Photo } from './media.js';
import type { Membership } from './membership.js';
import type { Package } from './package.js';
import type {
  MarketplaceGalleryTout,
  MarketplaceTout,
  MarketplaceToutType,
} from './tout.js';
import type { UserName } from './user.js';

/**
 * We use the ! suffix for required (non-null) properties here
 * (see https://www.typescriptlang.org/docs/handbook/release-notes/typescript-2-7.html#definite-assignment-assertions)
 * to make it simpler to initialize their values outside of a constructor
 */
export class Coach {
  // this is the full golfpro_id-####
  id!: string;
  // this is just the numbers in the ID
  coachId!: string;
  userId!: string;
  photo!: Photo;
  coverPhoto?: Photo;
  businessLogo?: Photo;
  profile!: CoachProfile;
  name!: UserName;
  publicSitePath!: string;

  experiencePlayingIds!: string[];
  experienceTeachingIds!: string[];
  sportIds!: string[];
  preferredStudentSkillLevelIds!: string[];

  // These are just ordered text strings
  achievements!: string[];
  certifications!: string[];

  colleges!: College[];
  coachLocations?: CoachLocation[];
  availabilities?: Availability[];
  packages?: Package[];
  memberships?: Membership[];
  events?: Event[];

  rates?: CoachRate[];

  get yearsExperience() {
    if (this.profile.startYear != null) {
      return new Date().getFullYear() - this.profile.startYear;
    }
    return null;
  }
}

export class PublicCoach extends Coach {
  registrationDate!: Date;
  sports?: string[];
  experiencePlaying?: string[];
  experienceTeaching?: string[];

  reviews?: CoachReview[];
  numReviews!: number;
  averageReview!: number;

  // index 0 = Sunday, 1 = Monday, etc.
  availableDaysOfTheWeek?: boolean[];

  statistics?: {
    lessonsTaught: number;
  };

  rateAggregation?: {
    average?: number;
    min?: number;
    max?: number;
  };

  touts?: MarketplaceToutType[];

  get galleryTouts(): MarketplaceGalleryTout[] {
    const galleryToutType = this.touts?.find((tout) => tout.isGallery);
    if (galleryToutType) {
      return galleryToutType.touts as MarketplaceGalleryTout[];
    }
    return [];
  }

  toutsOfType(toutTypeCode: string): MarketplaceTout[] {
    return (
      this.touts?.find((toutType) => toutType.code === toutTypeCode)?.touts ||
      []
    );
  }

  isBookable(): boolean {
    return (
      this.hasServices() || this.hasEventMemberships() || this.hasPackages()
    );
  }

  hasEventMemberships(): boolean {
    return !!this.memberships?.some((m) => m.hasEventsOnly());
  }

  hasMemberships(): boolean {
    return isThriveCoach() && !!this.memberships?.length;
  }

  hasServices(): boolean {
    return (
      (Array.isArray(this.availabilities) && this.availabilities.length > 0) ||
      !!this.events?.length
    );
  }

  hasPackages(): boolean {
    return !!this.packages?.length || this.hasMemberships();
  }
}

export class CoachLocation {
  isPrimary!: boolean;
  radius?: number;
  encryptedId?: string;
  isAdmin?: boolean;
  isGroupPro?: boolean;
  encryptedGroupFacilityId?: string;

  location!: Location;
}

export type ExperienceTeaching = {
  id: string;
  code: string;
  label: string;
};

// TODO fetch this in `useAPIStaticData`
export const experienceTeachingOptions: ExperienceTeaching[] = [
  { id: '15', code: 'BEGINNER', label: 'Beginner' },
  { id: '16', code: 'JUNIOR', label: 'Junior' },
  { id: '17', code: 'HIGH_SCHOOL', label: 'High School' },
  { id: '18', code: 'COLLEGE', label: 'College' },
  { id: '19', code: 'PROFESSIONAL', label: 'Professional' },
];
