import { replaceUrlEncodedEntities } from '../../utils/stringHelpers.js';
import { Sport } from '../sport.js';

export type DbSport = {
  sport_id: string;
  code: string;
  name: string;
};

export const fromDbSport = (dbSport: DbSport): Sport => {
  const sport = new Sport();

  sport.sportId = dbSport.sport_id;
  sport.code = dbSport.code;
  sport.name = replaceUrlEncodedEntities(dbSport.name);

  return sport;
};

export type DbMarketplaceSport = {
  id: string;
  code: string;
  name: string;
};

export const fromDbMarketplaceSport = (dbSport: DbMarketplaceSport): Sport => {
  const sport = new Sport();

  sport.sportId = dbSport.id;
  sport.code = dbSport.code;
  sport.name = replaceUrlEncodedEntities(dbSport.name);

  return sport;
};

export type DbElasticSearchSport = {
  id: number;
  code: string;
  name: string;
};
