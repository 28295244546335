import history from 'history/hash'; // eslint-disable-line node/file-extension-in-import
import React, { useContext } from 'react';

import EventCard from '../components/EventCard.js';
import { AppAttrsCtx } from '../context/AppAttrsCtx.js';
import { useDefaultSearchRequest } from '../hooks/useDefaultSearchRequest.js';
import { useSearchAPI } from '../hooks/useSearchAPI.js';

const EventsView = () => {
  const appAttributes = useContext(AppAttrsCtx);

  useDefaultSearchRequest();
  const { results: data } = useSearchAPI();

  return (
    <div className="tssm-events">
      {data && (
        <>
          <p className="tssm-events-title">
            {data && data.results.events.hits.length > 0 && (
              <>
                <span className="tssm-events-title-txt"> Upcoming Events</span>
                <span className="tssm-events-title-num">
                  {data.results.events.hits.length < 100
                    ? `${data.results.events.hits.length}`
                    : `100+`}
                </span>
              </>
            )}
          </p>
          <div className="tssm-events-ctn">
            {data.results.events.hits.slice(0, 4).map((event, eventIndex) => (
              <EventCard key={eventIndex} event={event} />
            ))}
          </div>
          {data.results.events.hits.length > 0 && (
            <div className="tssm-events-browse-ctn">
              <hr className="ts-divider tssm-events-browse-divider" />
              <a
                href={(() => {
                  const queryParams = new URLSearchParams(
                    history.location.search,
                  );
                  queryParams.set('results', 'events');
                  const url = new URL(
                    `${appAttributes.navbarTargetPath}#/search/map?${queryParams}`,
                    window.location.origin,
                  ).toString();
                  return url;
                })()}
                className="ts-btn tssm-events-browse"
              >
                BROWSE
              </a>
              <hr className="ts-divider tssm-events-browse-divider" />
            </div>
          )}
        </>
      )}
    </div>
  );
};
export default EventsView;
