import { constructApiUrl, useSingletonAPIRequest } from '@thrivesports/shared';
import { useContext, useEffect, useMemo, useState } from 'react';

import { AppAttrsCtx } from '../context/AppAttrsCtx.js';
import { UserLocationCtx } from '../context/UserLocationCtx.js';
import { buildFetcher, SearchAPIParams } from '../utils/searchApi.js';
import { useSearchParams } from './useSearchParams.js';

export const defaultSearchRadius = 25;
export const defaultResultSetSize = 100;

export const allSports = 'ANY';

/**
 * This hook will perform a search from any given expected URL query parameters.
 *
 * It is safe to use this on multiple components as it uses a syncManager
 * to prevent simultaneous fetches.
 */
export const useDefaultSearchAPI = () => {
  const appAttributes = useContext(AppAttrsCtx);
  const { latLng } = useContext(UserLocationCtx);

  const [fetchParams, setFetchParams] = useState<SearchAPIParams>();

  const fetcher = useMemo(() => {
    if (fetchParams != null) {
      return buildFetcher(fetchParams);
    }
    return undefined;
  }, [fetchParams]);

  const { results, status } = useSingletonAPIRequest('defaultSearch', fetcher);

  const { latitude: urlLatitude, longitude: urlLongitude } = useSearchParams();

  useEffect(() => {
    if (urlLatitude != null && urlLongitude != null) {
      return;
    }
    const apiSearchURL = constructApiUrl(appAttributes.targetApi, 'v1/search');

    apiSearchURL.searchParams.append('lat', latLng.lat.toString());
    apiSearchURL.searchParams.append('lon', latLng.lng.toString());

    apiSearchURL.searchParams.append('radius', `${defaultSearchRadius}`);
    apiSearchURL.searchParams.append('size', `${defaultResultSetSize}`);

    setFetchParams({
      url: apiSearchURL.toString(),
      partner: appAttributes.partner,
    });
  }, [
    appAttributes.partner,
    appAttributes.targetApi,
    latLng,
    urlLatitude,
    urlLongitude,
  ]);

  return { results, status };
};
