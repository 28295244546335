export class Location {
  id!: string;

  address?: string;
  city!: string;
  stateProvince!: string;
  zipCode!: string;
  country!: string;

  isPrivate!: boolean;

  encryptedId?: string;
  point?: GeoPoint;

  get isFacility(): boolean {
    return !!this.address;
  }

  getName() {
    const maybeFacility = this as unknown as Facility;
    if (maybeFacility.name) {
      return maybeFacility.name;
    }
    return `${this.city}, ${this.stateProvince}`;
  }
}

export class PrivateLocation extends Location {
  static type = 'location';
}

export class Facility extends Location {
  name!: string;

  static type = 'facility';
}

export type GeoPoint = {
  lat: number;
  lon: number;
};
