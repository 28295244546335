import type { RequestData } from './requestData.js';
import { ApiResponse } from './response.js';
import { ThriveBaseApiClient } from './thriveBaseApiClient.js';
import type { JsonResponse, RequestOptions } from './types.js';

export class ThrivePhpApiClient extends ThriveBaseApiClient {
  public async request<TResult>(
    data: RequestData,
  ): Promise<ApiResponse<TResult>> {
    const url = `${window.Thriv.Config.baseUrl}/${data.path}`;
    const fetchData = requestDataAsFetchOptions(data);
    const fetchResponse = await fetch(url, fetchData);

    const response = new ApiResponse<TResult>(
      fetchResponse.ok,
      fetchResponse.status,
    );
    const responseJson = (await fetchResponse.json()) as JsonResponse;
    this._parseResponseJson(response, responseJson);
    return response;
  }

  // use XMLHttpRequest. For older browsers and for things like upload progress
  public async xmlHttpRequest<TResult>(
    data: RequestData,
    options?: RequestOptions,
  ): Promise<ApiResponse<TResult>> {
    const requestObj = new XMLHttpRequest();
    requestObj.open(data.method, `${window.Thriv.Config.baseUrl}/${data.path}`);

    requestObj.setRequestHeader('x-thriv-react', 'true');
    if (options?.headers) {
      Object.entries(options.headers).forEach(([key, value]) => {
        requestObj.setRequestHeader(key, value);
      });
    }

    let handleRequestCompletion: (() => void) | null = null;

    const removeListeners = () => {
      // we're done; remove listeners
      if (options?.progressListener) {
        requestObj.upload.removeEventListener(
          'progress',
          options.progressListener,
        );
      }
      if (handleRequestCompletion != null) {
        requestObj.removeEventListener('load', handleRequestCompletion);
      }
    };

    const uploadResult = await new Promise<ApiResponse<TResult>>(
      (resolve, reject) => {
        handleRequestCompletion = () => {
          const response = new ApiResponse<TResult>(
            requestObj.status >= 200 && requestObj.status < 300,
            requestObj.status,
          );
          const responseJson = JSON.parse(
            requestObj.responseText,
          ) as JsonResponse;
          this._parseResponseJson(response, responseJson);
          resolve(response);

          removeListeners();
        };

        try {
          if (options?.progressListener) {
            requestObj.upload.addEventListener(
              'progress',
              options.progressListener,
            );
          }

          // request finished event
          requestObj.addEventListener('load', handleRequestCompletion);

          // send POST request to server
          const body = data.body ? data.asFormData() : null;
          requestObj.send(body);
        } catch (e) {
          reject(e);
          removeListeners();
        }
      },
    );

    return uploadResult;
  }

  /**
   * Garbage in, garbage out.
   *
   * There is little consistency between API response JSON formats, so we try and
   * unify them in this abomination of a function
   *
   * @param parseInto
   * @param responseJson
   */
  private _parseResponseJson<TResult>(
    parseInto: ApiResponse<TResult>,
    responseJson: JsonResponse,
  ) {
    if ('errorMessage' in responseJson) {
      parseInto.errorMessage = responseJson.errorMessage as string;
      parseInto.success = false;
    } else if ('error_code' in responseJson) {
      parseInto.errorCode = responseJson.error_code as string;
      if ('error_msg' in responseJson) {
        parseInto.errorMessage = responseJson.error_msg as string;
      }
      parseInto.success = false;
    } else if ('error_codes' in responseJson) {
      parseInto.errorCodes = responseJson.error_codes as string[];
      parseInto.success = false;
    } else if ('error_msg' in responseJson) {
      if ('error' in responseJson) {
        parseInto.errorCode = `${responseJson.error}`;
        parseInto.errorMessage = responseJson.error_msg as string;
      } else {
        parseInto.errorCode = responseJson.error_msg as string;
      }
      parseInto.success = false;
    }
    if (parseInto.success === false) {
      return;
    }

    if ('data' in responseJson) {
      parseInto.result = responseJson.data as TResult;
    } else if (Array.isArray(responseJson)) {
      parseInto.result = responseJson as unknown as TResult;
    } else if (responseJson.result) {
      parseInto.result = responseJson.result as TResult;
    } else {
      parseInto.result = responseJson as TResult;
    }

    if ('success' in responseJson) {
      parseInto.success = responseJson.success as boolean;
    }
  }
}

export const requestDataAsFetchOptions = (
  requestData: RequestData,
): RequestInit => {
  const fetchOptions: RequestInit = {
    method: requestData.method,
    headers: new Headers({
      'x-thriv-react': 'true',
      ...requestData.options?.headers,
    }),
    // for requests to the PHP backend, include credentials
    credentials: 'include',
  };
  if (requestData.body) {
    if (
      requestData.options?.contentType == null ||
      requestData.options.contentType === 'application/x-www-form-urlencoded'
    ) {
      (fetchOptions.headers as Headers).append(
        'Content-Type',
        'application/x-www-form-urlencoded',
      );
      fetchOptions.body = requestData.asUrlSearchParams();
    } else if (requestData.options?.contentType === 'multipart/form-data') {
      fetchOptions.body = requestData.asFormData();
    }
  }
  return fetchOptions;
};
