import { Selector } from '@thrivesports/design-system';
import { constructApiUrl } from '@thrivesports/shared';
import history from 'history/hash'; // eslint-disable-line node/file-extension-in-import
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { debounceTime, distinctUntilChanged, Subject } from 'rxjs';

import { AppAttrsCtx } from '../context/AppAttrsCtx.js';
import { UserLocationCtx } from '../context/UserLocationCtx.js';
import {
  allSports,
  defaultSearchRadius,
} from '../hooks/useDefaultSearchAPI.js';
import { useSearchParams } from '../hooks/useSearchParams.js';

export interface AutocompletePlaceInterface {
  description: string;
  placeid: string;
}

type SelectedLocationData = {
  name: string;
  latitude: string;
  longitude: string;
};

const SearchBarView = () => {
  const appAttributes = useContext(AppAttrsCtx);
  const userLocation = useContext(UserLocationCtx);

  const { term, sport: urlSport, radius } = useSearchParams();
  const [searchText, setSearchText] = useState(term || '');
  const [sport, setSport] = useState(urlSport);

  const [onSearchTextSubject] = useState(new Subject<string>());

  const [autocompletePlaces, setAutocompletePlaces] = useState<
    google.maps.places.QueryAutocompletePrediction[]
  >([]);
  const [hasFocus, setHasFocus] = useState<boolean>(false);
  const [shouldLocDropdown, setShouldLocDropdown] = useState<boolean>(true);

  const searchInputRef = useRef<HTMLInputElement>(null);
  const searchResultsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setSearchText(term || '');
    if (!term) {
      setAutocompletePlaces([]);
    }
  }, [term]);

  useEffect(() => {
    setSport(urlSport);
  }, [urlSport]);

  useEffect(() => {
    if (window.google?.maps != null && window.google.maps.places == null) {
      google.maps.importLibrary('places');
    }
  }, []);

  useEffect(() => {
    const listenerFunction = (event: MouseEvent) => {
      if (searchInputRef) {
        if (event.target) {
          const target = event.target as HTMLElement;
          if (searchResultsRef.current?.contains(target)) {
            return;
          }
          if (!searchInputRef.current?.contains(target)) {
            setHasFocus(false);
          } else {
            setHasFocus(true);
          }
        }
      }
    };
    window.addEventListener('click', listenerFunction);
    return () => {
      window.removeEventListener('click', listenerFunction);
    };
  }, []);

  const locationAutocompleteRes = useCallback(
    (txt: string) => {
      // https://maps.googleapis.com/maps/api/place/details/output?parameters
      // https://stage-api.thrivsports.com/api/v2/proxy-google-places/place/details/output?parameters
      // https://maps.googleapis.com

      const autocompleteService = new google.maps.places.AutocompleteService();
      const findPlaceOptions: google.maps.places.QueryAutocompletionRequest = {
        input: txt.trim(),
        types: ['(regions)'],
        componentRestrictions: { country: ['us', 'ca'] },
      } as google.maps.places.QueryAutocompletionRequest;
      if (
        appAttributes.currentLat != null &&
        appAttributes.currentLng != null
      ) {
        findPlaceOptions.location = new google.maps.LatLng({
          lat: appAttributes.currentLat,
          lng: appAttributes.currentLng,
        });
      }
      autocompleteService.getPlacePredictions(
        findPlaceOptions,
        (results, status) => {
          if (
            status === google.maps.places.PlacesServiceStatus.OK &&
            results != null &&
            results.length > 0
          ) {
            setAutocompletePlaces(results);
          }
        },
      );
    },
    [appAttributes.currentLat, appAttributes.currentLng],
  );

  useEffect(() => {
    onSearchTextSubject
      .pipe(debounceTime(1000), distinctUntilChanged())
      .subscribe((val) => {
        const newVal = val.trim();
        if (newVal !== '' && newVal.length > 3) {
          locationAutocompleteRes(val);
        } else {
          setAutocompletePlaces([]);
        }
      });
  }, [locationAutocompleteRes, onSearchTextSubject]);

  const navigateTo = useCallback(
    (currentLocation?: SelectedLocationData) => {
      let priorHashPath = window.location.hash.substring(
        0,
        window.location.hash.indexOf('?'),
      );
      if (priorHashPath.length === 0) {
        priorHashPath = '#/';
      }
      const newSearchParams = new URLSearchParams(history.location.search);
      if (sport) {
        newSearchParams.set('sport', sport);
      }
      if (currentLocation) {
        newSearchParams.set('latitude', currentLocation.latitude);
        newSearchParams.set('longitude', currentLocation.longitude);
        newSearchParams.set('term', currentLocation.name);
      }

      if (radius === 'dynamic') {
        newSearchParams.set('radius', `${defaultSearchRadius}`);
      }

      // Here we need to honor the navbarTargetPath, so we can't just set the
      // searchParams using history.push like we do elsewhere
      const newHash = `${priorHashPath}?${newSearchParams.toString()}`;
      const newHref = `${window.location.origin}${appAttributes.navbarTargetPath}${newHash}`;
      window.location.assign(newHref);
    },
    [appAttributes.navbarTargetPath, radius, sport],
  );

  const findPlace = useCallback(
    (place: google.maps.places.QueryAutocompletePrediction) => {
      const service = new window.google.maps.places.PlacesService(
        document.createElement('div'),
      );
      return new Promise<SelectedLocationData>((resolve) => {
        service.getDetails(
          { placeId: place.place_id || '' },
          (results, status) => {
            if (
              status === google.maps.places.PlacesServiceStatus.OK &&
              results != null &&
              results.geometry &&
              results.geometry.location
            ) {
              resolve({
                name: place.description,
                latitude: results.geometry.location.lat().toString(),
                longitude: results.geometry.location.lng().toString(),
              });
            }
          },
        );
      });
    },
    [],
  );

  return (
    <div className={`tssm-search-bar ${hasFocus ? 'active' : ''}`}>
      <div
        onFocus={(e) => {
          if (e.currentTarget.contains(e.target)) {
            setHasFocus(true);
          }
        }}
        className="tssm-search-bar-inner"
      >
        <span className="tsi-location"></span>
        <input
          ref={searchInputRef}
          value={searchText}
          onChange={(event) => {
            setSearchText(event.target.value);
            onSearchTextSubject.next(event.target.value);
          }}
          type="text"
          placeholder={appAttributes.navbarText || 'Location'}
          className="tssm-search-bar-location-in"
        />
        <span className="tssm-search-bar-inner-divider"></span>
        <Selector
          icon={() => <span className={`tsi-controls ts-selector-icon`}></span>}
          onSelect={(newVal: string) => {
            setSport(newVal);
          }}
          onOpen={() => {
            setShouldLocDropdown(false);
          }}
          onClose={() => {
            setShouldLocDropdown(true);
          }}
          defaultSelected={
            appAttributes.sportDropdownWhitelist.find((s) => s.code === sport)
              ?.code || allSports
          }
          options={appAttributes.sportDropdownWhitelist.map((s) => ({
            valueOf: () => s.code,
            toString: () => s.name,
            comp: <p className="tssm-search-bar-spot-item">{s.name}</p>,
          }))}
          className={`tssm-search-bar-sel ${
            appAttributes.sportDropdownWhitelist.find((s) => s.code === sport)
              ?.code === urlSport || urlSport == null
              ? ''
              : 'active'
          }`}
        />
        <button
          onClick={async () => {
            // If the searchText has something in it, get the place's lat/long
            // and then do the search
            if (searchText.trim() !== '') {
              const apiSearchURL = constructApiUrl(
                appAttributes.targetApi,
                'v2/proxy-google-places/autocomplete/json',
              );
              apiSearchURL.searchParams.append('input', searchText.trim());
              apiSearchURL.searchParams.append(
                'location',
                `${userLocation.latLng.lat},${userLocation.latLng.lng}`,
              );
              apiSearchURL.searchParams.append('types', '(regions)');
              apiSearchURL.searchParams.append(
                'fields',
                ['geometry'].toString(),
              ); // Doesn't work for some reason :(

              try {
                const rawAutocomplete = await fetch(apiSearchURL.toString());
                const autocomplete = await rawAutocomplete.json();
                const predictions =
                  autocomplete.predictions as google.maps.places.AutocompletePrediction[];
                if (predictions.length > 0) {
                  const selectedLocation = await findPlace(predictions[0]);

                  navigateTo(selectedLocation);
                }
              } catch (err) {
                // eslint-disable-next-line no-console
                console.error(err);
              }
            } else {
              navigateTo();
            }
          }}
          className="tssm-search-bar-inner-search-btn"
        >
          <span className="tsi-search"></span>
        </button>
      </div>
      <div
        ref={searchResultsRef}
        className={`tssm-search-bar-places  ${
          autocompletePlaces.length === 0 ||
          hasFocus === false ||
          shouldLocDropdown === false
            ? 'hidden'
            : ''
        }`}
      >
        {autocompletePlaces.map((place, placeIndex) => (
          <div
            key={placeIndex}
            onClick={() => {
              setSearchText(place.description);
              findPlace(place);
              setHasFocus(false);
            }}
            className="tssm-search-bar-places-item"
          >
            {place.description}
          </div>
        ))}
      </div>
    </div>
  );
};
export default SearchBarView;
