import { parse, startOfDay } from 'date-fns';

import { Availability, AvailabilityRate } from '../availability.js';
import {
  DbMarketplaceLocation,
  fromDbMarketplaceLocation,
} from './location.js';

export type DbAvailability = {
  id: string;
  title: string;
  start_date: string;
  end_date: string;
  start_time: string;
  end_time: string;
  on_sunday: string;
  on_monday: string;
  on_tuesday: string;
  on_wednesday: string;
  on_thursday: string;
  on_friday: string;
  on_saturday: string;
  approval_required: string;

  location: DbMarketplaceLocation;
  rates: DbAvailabilityRate[];
};

export type DbAvailabilityRate = {
  id: string;
  title: string;
  student_count: string;
  description: string;
  duration: string;
  rate: string;
  golfer_type: string;
  golfer_type_code: string;
  lesson_type: string;
  book_now_url: string;
};

export type DbElasticSearchAvailability = {
  days_of_week_bitmask: number;
};

export const daysOfTheWeekBitmask = {
  SUNDAY: 1 << 0,
  MONDAY: 1 << 1,
  TUESDAY: 1 << 2,
  WEDNESDAY: 1 << 3,
  THURSDAY: 1 << 4,
  FRIDAY: 1 << 5,
  SATURDAY: 1 << 6,
};

export const fromDbAvailability = (
  dbAvailability: DbAvailability,
): Availability => {
  const rates: AvailabilityRate[] = dbAvailability.rates.map((dbRate) => ({
    id: dbRate.id,
    title: dbRate.title,
    description: dbRate.description,
    studentCount: parseInt(dbRate.student_count, 10),
    duration: parseInt(dbRate.duration, 10),
    rate: parseFloat(dbRate.rate) || 0,
    golferTypeCode: dbRate.golfer_type_code,
    golferType: dbRate.golfer_type,
    lessonType: dbRate.lesson_type,
    bookNowURL: dbRate.book_now_url,
  }));

  const startTime = parse(dbAvailability.start_time, 'HH:mm:ss', new Date());
  const endTime = parse(dbAvailability.end_time, 'HH:mm:ss', new Date());

  const availability = new Availability();
  availability.id = dbAvailability.id;
  availability.title = dbAvailability.title;

  availability.start = startOfDay(
    parse(dbAvailability.start_date, 'yyyy-MM-dd', new Date()),
  );
  availability.startHour = startTime.getHours();
  availability.startMinutes = startTime.getMinutes();
  if (dbAvailability.end_date) {
    availability.end = parse(dbAvailability.end_date, 'yyyy-MM-dd', new Date());
  }
  availability.endHour = endTime.getHours();
  availability.endMinutes = endTime.getMinutes();

  // Index 0 is Sunday, not Monday
  availability.activeDays = [
    dbAvailability.on_sunday === '1',
    dbAvailability.on_monday === '1',
    dbAvailability.on_tuesday === '1',
    dbAvailability.on_wednesday === '1',
    dbAvailability.on_thursday === '1',
    dbAvailability.on_friday === '1',
    dbAvailability.on_saturday === '1',
  ];

  availability.approvalRequired = dbAvailability.approval_required !== 'no';

  availability.rates = rates;
  availability.location = fromDbMarketplaceLocation(dbAvailability.location);

  return availability;
};
