import mitt, { Emitter } from 'mitt';

export type LoadStatus = 'idle' | 'loading' | 'done' | 'error';

export const keyValueChanged = 'VALUE_CHANGED';
export const keyStatusChanged = 'STATUS_CHANGED';

type Events<T> = {
  [keyValueChanged]: T;
  [keyStatusChanged]: LoadStatus;
};

export class SyncManager<T> {
  private status: LoadStatus;
  private value: T | undefined;
  readonly emitter: Emitter<Events<T>>;

  constructor() {
    this.status = 'idle';
    this.emitter = mitt<Events<T>>();
  }

  getSyncStatus() {
    return this.status;
  }
  setSyncStatus(status: LoadStatus) {
    this.status = status;
  }

  getValue(): T | undefined {
    return this.value;
  }
  setValue(value: T): void {
    this.value = value;
  }
}
