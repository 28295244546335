import type { Location } from 'history';
import history from 'history/hash'; // eslint-disable-line node/file-extension-in-import
import { useCallback, useEffect, useState } from 'react';

const getURLParam = (param: string) =>
  new URLSearchParams(history.location.search).get(param);

export const useSearchParams = () => {
  const [latitude, setLatitude] = useState(getURLParam('latitude'));
  const [longitude, setLongitude] = useState(getURLParam('longitude'));
  const [zoom, setZoom] = useState(getURLParam('zoom'));
  const [radius, setRadius] = useState(getURLParam('radius'));
  const [resultType, setResultType] = useState<'coaches' | 'events'>(
    getURLParam('results') === 'events' ? 'events' : 'coaches',
  );
  const [sport, setSport] = useState(getURLParam('sport'));
  const [term, setTerm] = useState(getURLParam('term'));

  const listener = useCallback(({ location }: { location: Location }) => {
    const currentQueryParams = new URLSearchParams(location.search);

    setLatitude(currentQueryParams.get('latitude'));
    setLongitude(currentQueryParams.get('longitude'));
    setZoom(currentQueryParams.get('zoom'));
    setRadius(currentQueryParams.get('radius'));
    setResultType(
      currentQueryParams.get('results') === 'events' ? 'events' : 'coaches',
    );
    setSport(currentQueryParams.get('sport'));
    setTerm(currentQueryParams.get('term'));

    if (
      !['coaches', 'events'].includes(currentQueryParams.get('results') || '')
    ) {
      setResultType('coaches');
    }
  }, []);

  // listen for changes in search params
  useEffect(() => {
    const unlisten = history.listen(listener);
    return unlisten;
  }, [listener]);

  // get the search params immediately
  useEffect(() => {
    listener({ location: history.location });
  }, [listener]);

  return {
    latitude,
    longitude,
    zoom,
    radius,
    resultType,
    sport,
    term,
  };
};
