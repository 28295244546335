export const initWindowForThriv = () => {
  window.Thriv = {
    Config: {
      baseUrl: '',
      companyName: '',
      navbarLogo: '',
      portalPageImg: '',
    },
    Session: {
      jwt: '',
    },
  };
};
