import { removeEmpty } from '../objUtils.js';
import { RequestData } from './requestData.js';
import type { ApiResponse } from './response.js';
import type { Params, RequestOptions, UnsanitizedFormBody } from './types.js';

export abstract class ThriveBaseApiClient {
  public abstract request<TResult>(
    data: RequestData,
  ): Promise<ApiResponse<TResult>>;

  public abstract xmlHttpRequest<TResult>(
    data: RequestData,
    options?: RequestOptions,
  ): Promise<ApiResponse<TResult>>;

  public get<TResult>(
    path: string,
    params?: Params,
  ): Promise<ApiResponse<TResult>> {
    let newPath = path;
    if (params != null) {
      newPath += `?${new URLSearchParams(params).toString()}`;
    }
    const requestData = new RequestData(newPath, 'GET');

    return this.request<TResult>(requestData);
  }

  public post<TResult>(
    path: string,
    data: UnsanitizedFormBody,
    options?: RequestOptions,
  ): Promise<ApiResponse<TResult>> {
    const body = removeEmpty(data);
    const requestData = new RequestData(path, 'POST', body, options);

    if (options?.progressListener) {
      return this.xmlHttpRequest(requestData, options);
    }
    return this.request<TResult>(requestData);
  }

  public delete<TResult>(
    path: string,
    params?: Params,
  ): Promise<ApiResponse<TResult>> {
    let newPath = path;
    if (params != null) {
      newPath += `?${new URLSearchParams(params).toString()}`;
    }
    const requestData = new RequestData(newPath, 'DELETE');

    return this.request<TResult>(requestData);
  }
}
