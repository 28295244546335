import type { GeoPoint, Location } from '@thrivesports/shared';
import { useContext, useMemo } from 'react';

import { UserLocationCtx } from '../context/UserLocationCtx.js';
import lineDistance from '../utils/geometry/lineDistance.js';

export type MapLocation = {
  point?: GeoPoint;
};

export const useClosestLocation = (locations?: Location[]) => {
  const { latLng } = useContext(UserLocationCtx);

  const closestLoc = useMemo(() => {
    if (!locations) {
      return null;
    }
    const locationsByDistance = locations?.sort((a, b) => {
      if (!a.point && !b.point) {
        return 0;
      }
      if (!a.point) {
        return -1;
      }
      if (!b.point) {
        return 1;
      }
      // Locations sorted by distance in miles
      const aD = lineDistance(a.point.lat, a.point.lon, latLng.lat, latLng.lng);
      const bD = lineDistance(b.point.lat, b.point.lon, latLng.lat, latLng.lng);
      return aD - bD;
    });
    return locationsByDistance[0];
  }, [locations, latLng.lat, latLng.lng]);

  return closestLoc;
};
