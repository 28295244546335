import {
  differenceInCalendarDays,
  format,
  formatDuration,
  intervalToDuration,
  parse,
  setDay,
  startOfDay,
} from 'date-fns';

export const parseDateFromDateAndTimeStrings = (
  dateStr: string,
  timeStr: string,
) => {
  const date = startOfDay(parse(dateStr, 'yyyy-MM-dd', new Date()));
  return parse(timeStr, 'HH:mm:ss', date);
};

export const durationPretty = (start: Date, end: Date, formats?: string[]) => {
  const duration = intervalToDuration({ start, end });
  return formatDuration(duration, { format: formats });
};

export const dateRangePretty = (
  start: Date,
  end: Date,
  dateFormat: string,
  customTimeFormat?: string,
) => {
  const isSameDay = differenceInCalendarDays(start, end) === 0;
  const timeFormat = customTimeFormat || 'h:mmbbb';
  const parts: string[] = [];
  if (isSameDay) {
    parts.push(format(start, dateFormat));
    parts.push(`${format(start, timeFormat)} to ${format(end, timeFormat)}`);
  } else {
    const dateTimeFormat = `${dateFormat} ${timeFormat}`;
    parts.push(`${format(start, dateTimeFormat)} to`);
    parts.push(format(end, dateTimeFormat));
  }

  return parts;
};

export const parseDbUtcTimestamp = (timestamp: string) =>
  parse(`${timestamp}Z`, 'yyyy-MM-dd HH:mm:ssX', new Date());

export const availableDaysOfTheWeek = (activeDays: boolean[]) => {
  if (activeDays.every((isActive) => isActive)) {
    return 'every day of the week';
  }
  if ([1, 2, 3, 4, 5].every((dayIndex) => activeDays[dayIndex])) {
    let label = 'on weekdays';
    if (activeDays[0]) {
      label += ' and Sunday';
    } else if (activeDays[6]) {
      label += ' and Saturday';
    }
    return label;
  }
  const days: string[] = [];
  activeDays.forEach((isActive, dayIndex) => {
    if (isActive) {
      const newDate = setDay(new Date(), dayIndex);
      days.push(format(newDate, 'eeee'));
    }
  });
  if (days.length === 0) {
    return null;
  }
  if (days.length === 1) {
    return `on ${days[0]}`;
  }
  return `on ${days.slice(0, days.length - 1).join(', ')} and ${
    days[days.length - 1]
  }`;
};

export const availableDaysOfTheWeekConcise = (activeDays: boolean[]) => {
  if (activeDays.every((isActive) => isActive)) {
    return 'Weekdays & Weekends';
  }
  if ([1, 2, 3, 4, 5].every((dayIndex) => activeDays[dayIndex])) {
    let label = 'Weekdays';
    if (activeDays[0]) {
      label += ' & Sundays';
    } else if (activeDays[6]) {
      label += ' & Saturdays';
    }
    return label;
  }

  let label = 'Weekdays';
  if (activeDays.reduce((acc, curr) => acc + (curr ? 1 : 0), 0) >= 3) {
    label = 'Weekdays';
  }
  if (activeDays[0]) {
    label += ' & Sundays';
  } else if (activeDays[6]) {
    label += ' & Saturdays';
  }
  return label;
};
