import React, { useContext } from 'react';

import LocationCard from '../components/LocationCard.js';
import { AppAttrsCtx } from '../context/AppAttrsCtx.js';

const SuggestedLocationsView = () => {
  const AppAttributes = useContext(AppAttrsCtx);

  return (
    <div className="tssm-suggested-locations">
      {AppAttributes.suggestedLocations.length > 0 && (
        <>
          <p className="tssm-suggested-locations-title">Popular Locations</p>
          <div className="tssm-suggested-locations-ctn">
            {AppAttributes.suggestedLocations.map((loc, locIndex) => (
              <LocationCard key={locIndex} location={loc} />
            ))}
          </div>
        </>
      )}
    </div>
  );
};
export default SuggestedLocationsView;
