import React from 'react';

export const DollarSign = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21.821"
    height="21.82"
    viewBox="0 0 21.821 21.82"
  >
    <path
      id="Path_5922"
      data-name="Path 5922"
      d="M12.814,3.246a10.45,10.45,0,0,0,4.211-.859A11.179,11.179,0,0,0,20.507.022a11.118,11.118,0,0,0,2.358-3.476,10.5,10.5,0,0,0,.854-4.217,10.45,10.45,0,0,0-.859-4.211A11.247,11.247,0,0,0,20.5-15.369a10.975,10.975,0,0,0-3.481-2.358,10.569,10.569,0,0,0-4.211-.848,10.529,10.529,0,0,0-4.206.848,11.059,11.059,0,0,0-3.481,2.358,11.12,11.12,0,0,0-2.364,3.487A10.509,10.509,0,0,0,1.9-7.67a10.438,10.438,0,0,0,.859,4.217A11.2,11.2,0,0,0,5.121.022,11.2,11.2,0,0,0,8.6,2.386,10.438,10.438,0,0,0,12.814,3.246ZM12.8-1.437a.518.518,0,0,1-.365-.14.488.488,0,0,1-.152-.376v-.764a3.961,3.961,0,0,1-1.96-.679A2.62,2.62,0,0,1,9.254-4.9a1.515,1.515,0,0,0-.034-.18.738.738,0,0,1-.022-.18.7.7,0,0,1,.2-.505.681.681,0,0,1,.511-.2.689.689,0,0,1,.449.146.947.947,0,0,1,.281.427,1.72,1.72,0,0,0,.545.887,2.03,2.03,0,0,0,1.106.449V-7.12l-.124-.022a4.674,4.674,0,0,1-2.072-.983,2.2,2.2,0,0,1-.7-1.7,2.422,2.422,0,0,1,.814-1.881,3.578,3.578,0,0,1,2.083-.87v-.741a.506.506,0,0,1,.152-.382.507.507,0,0,1,.365-.146.521.521,0,0,1,.376.146.506.506,0,0,1,.152.382v.741a3.8,3.8,0,0,1,1.847.7,2.492,2.492,0,0,1,.994,1.438,1,1,0,0,1,.045.191,1.447,1.447,0,0,1,.011.18.64.64,0,0,1-.2.489.7.7,0,0,1-.5.185.755.755,0,0,1-.741-.55,1.823,1.823,0,0,0-.539-.848,1.9,1.9,0,0,0-.921-.421v2.9l.191.034a4.172,4.172,0,0,1,2.151.938,2.355,2.355,0,0,1,.724,1.8,2.448,2.448,0,0,1-.882,1.982,3.83,3.83,0,0,1-2.184.848v.764a.492.492,0,0,1-.152.371A.521.521,0,0,1,12.8-1.437Zm-.517-7.154v-2.628a1.836,1.836,0,0,0-1.028.466,1.135,1.135,0,0,0-.376.837,1.057,1.057,0,0,0,.32.786,2.4,2.4,0,0,0,1.016.517Zm1.044,1.718v2.819a1.918,1.918,0,0,0,1.179-.46,1.263,1.263,0,0,0,.393-.943,1.161,1.161,0,0,0-.3-.814,2.206,2.206,0,0,0-1.067-.533Z"
      transform="translate(-1.898 18.575)"
      fill="#3d3d6b"
    />
  </svg>
);
