const currentScript = document.currentScript as HTMLScriptElement;

type AttrTypes =
  | 'string'
  | 'number'
  | 'integer'
  | 'float'
  | 'boolean'
  | 'stringArray'
  | 'numArray'
  | 'integerArray'
  | 'floatArray'
  | 'jsonFetch';

export function ScriptAttr<AttrType = string>() {
  return (
    target: any,
    propertyKey: string,
    attributeName: string = propertyKey,
    propertyType: AttrTypes,
  ) => {
    const currentScriptAttrs = currentScript.attributes;

    const item = currentScriptAttrs.getNamedItem(attributeName);
    if (propertyType == null || propertyType === 'string') {
      if (item) {
        // (attrs[propertyKey] as string) = item.value.toString();
        Object.defineProperty<AttrType>(
          target,
          propertyKey,
          item.value.toString(),
        );
      }
    } else if (propertyType === 'number') {
      // Either int or float
      if (item && !Number.isNaN(item.value as unknown as number)) {
        const v: string = item.value;
        // (attrs[propertyKey] as number) = Number(v);
        Object.defineProperty<AttrType>(target, propertyKey, Number(v));
      }
    } else if (propertyType === 'integer') {
      if (item && !Number.isNaN(item.value as unknown as number)) {
        const v: string = item.value;
        // (attrs[propertyKey] as number) = parseInt(v);
        Object.defineProperty<AttrType>(target, propertyKey, parseInt(v, 10));
      }
    } else if (propertyType === 'float') {
      if (item && !Number.isNaN(item.value as unknown as number)) {
        const v: string = item.value;
        // (attrs[propertyKey] as number) = parseInt(v);
        Object.defineProperty<AttrType>(target, propertyKey, parseFloat(v));
      }
    } else if (propertyType === 'boolean') {
      if (item) {
        // (attrs[propertyKey] as Boolean) = Boolean(item.value);
        Object.defineProperty<AttrType>(
          target,
          propertyKey,
          Boolean(item.value),
        );
      }
    } else if (propertyType === 'stringArray') {
      if (item) {
        // (attrs[propertyKey] as string[]) = item.value.toString().split(',');
        Object.defineProperty<AttrType>(
          target,
          propertyKey,
          item.value.toString().split(','),
        );
      }
    } else if (propertyType === 'numArray') {
      // Can be int or float. Must be seperated with a comma

      if (item) {
        // (attrs[propertyKey] as number[]) = item.value.toString().split(',').map(v=>Number(v));
        Object.defineProperty<AttrType>(
          target,
          propertyKey,
          item.value
            .toString()
            .split(',')
            .map((v) => Number(v)),
        );
      }
    } else if (propertyType === 'integerArray') {
      if (item) {
        // (attrs[propertyKey] as number[]) = item.value.toString().split(',').map(v=>parseInt(v));
        Object.defineProperty<AttrType>(
          target,
          propertyKey,
          item.value
            .toString()
            .split(',')
            .map((v) => parseInt(v, 10)),
        );
      }
    } else if (propertyType === 'floatArray') {
      if (item) {
        // (attrs[propertyKey] as number[]) = item.value.toString().split(',').map(v=>parseFloat(v));
        Object.defineProperty<AttrType>(
          target,
          propertyKey,
          item.value
            .toString()
            .split(',')
            .map((v) => parseFloat(v)),
        );
      }
    } else if (propertyType === 'jsonFetch') {
      const fetchJsonProm = new Promise<any>((fetchJsonResolve) => {
        if (item) {
          fetch(item.value)
            .then((raw) => raw.text())
            .then((rawText) => {
              Object.defineProperty<AttrType>(
                target,
                propertyKey,
                JSON.parse(rawText),
              );
              fetchJsonResolve(JSON.parse(rawText));
            })
            .catch((err) => {
              console.error(
                `Error retrieving ${attributeName} from url provided`,
              );
              console.error(err);
              fetchJsonResolve({
                error: `Error retrieving ${attributeName} from url provided`,
              });
            });
        } else {
          fetchJsonResolve(undefined);
        }
      });
      Object.defineProperty<AttrType>(target, propertyKey, fetchJsonProm);
    }
    Object.defineProperty<AttrType>(target, propertyKey, '');
  };
}
