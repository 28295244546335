import { Sport } from '@thrivesports/shared';
import React, { PropsWithChildren, useMemo } from 'react';

import { defaultScriptAttrRequirements } from '../attributes/defaultScriptAttrRequirements.js';
import type { ScriptAttrRequirementsInterface } from '../attributes/scriptAttrRequirements.js';
import { useAPISports } from '../hooks/useAPISports.js';

export const AppAttrsCtx = React.createContext(defaultScriptAttrRequirements);

export const AppAttrsContextProvider: React.FC<
  PropsWithChildren<{ appAttributes: ScriptAttrRequirementsInterface }>
> = ({ appAttributes, children }) => {
  const { sports } = useAPISports();

  const whiteListedSports = useMemo(() => {
    let sportList = appAttributes.sportDropdownWhitelist;
    if (!Array.isArray(sportList) || sportList.length === 0) {
      sportList = sports;
    }

    if (sportList[0]?.code !== 'ANY') {
      const anySport = new Sport();
      anySport.code = 'ANY';
      anySport.name = 'All Sports';
      sportList.unshift(anySport);
    }
    return sportList;
  }, [appAttributes.sportDropdownWhitelist, sports]);

  const attrs: ScriptAttrRequirementsInterface = {
    ...appAttributes,
    sportDropdownWhitelist: whiteListedSports,
  };

  return <AppAttrsCtx.Provider value={attrs}>{children}</AppAttrsCtx.Provider>;
};
