import './waitForGoogleMaps.scss';

import React, { useEffect, useState } from 'react';

export const WaitForGoogleMaps = (props: { children: React.ReactNode }) => {
  const [loadedState, setLoadedState] = useState<
    'loading' | 'loaded' | 'error'
  >('loading');

  useEffect(() => {
    waitFunction()
      .then(() => {
        setLoadedState('loaded');
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error('Error in WaitForGoogleMaps!', err);
        setLoadedState('error');
      });
  }, []);

  const waitFunction = (): Promise<void> => {
    const prom = new Promise<void>((resolvePromise) => {
      if (window.google) {
        resolvePromise(); // GM is loaded
      } else {
        const interval = setInterval(() => {
          // Check every 0.5s to see if GM is loaded
          if (window.google) {
            clearInterval(interval);
            resolvePromise(); // GM is loaded
          }
        }, 500);
      }
    });
    return prom;
  };

  if (loadedState === 'loaded') {
    return <>{props.children}</>;
  }
  if (loadedState === 'loading') {
    return <div className="ts-wait-for-google-maps">Loading Maps...</div>;
  }
  return (
    <div className="ts-wait-for-google-maps-error">
      Something went wrong with maps
    </div>
  );
};
